















import Vue from "vue";
import Component from "vue-class-component";
import { Item } from "@/services/model/item";

const ItemProps = Vue.extend({
  props: {
    items: Array as () => Array<Item>,
  },
});

@Component
export default class ItemTable extends ItemProps {
  headers = [
    { text: "Title", align: "start", value: "title" },
    { text: "Author", value: "author" },
    { text: "Condition", value: "condition" },
    { text: "Price", value: "price" },
    { text: "Reference", value: "reference" },
    { text: "Publisher", value: "publisher" },
    { text: "Category", value: "category" },
    { text: "Sold", value: "soldAt" },
  ];

  formatDate(item: Item) {
    if (item.soldAt) {
      const date = new Date(item.soldAt);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    }
  }
}
