





















import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Component from "vue-class-component";
import { Session } from "@/store/session.module";
import ItemTable from "@/components/ItemTable.vue";
import ListingSession from "@/components/ListingSession.vue";

@Component({
  components: {
    ItemTable,
    ListingSession,
  },
  computed: mapGetters("session", ["getSessions"]),
  methods: mapActions("session", ["loadSessions", "createSession"]),
})
export default class ListingSessions extends Vue {
  getSessions!: Array<Session>;
  loadSessions!: () => Promise<Object>;
  createSession!: () => Promise<Object>;

  mounted() {
    this.loadSessions();
  }
}
