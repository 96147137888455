import axios from "axios";

const endpoint = "exportListing"

class ExportService {

    exportListing(sessionId: string): Promise<Array<Export>> {

        const currentDate: Date = new Date()
        const roundedDate: Date = roundDateToHour(currentDate)

        // start and stop days are by default 30 days apart
        const startDate = addDays(roundedDate, 1).toISOString();
        const stopDate = addDays(roundedDate, 31).toISOString();

        const req = new ExportListingSessionRequest(sessionId, startDate, stopDate)

        return axios.post<ExportListingSessionResponse>(endpoint, JSON.stringify(req))
            .then((response) => {
                return response.data.exports;
            });
    }

    downloadExports(urls: string[]): Promise<Array<void>> {
        const instance = axios.create();
        instance.defaults.headers.common = {}
        return Promise.all(
            urls.map(url =>
                instance.get(url).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    var url = urls[0]

                    var from = url.lastIndexOf('/') + 1;
                    var to = url.indexOf('?');

                    var fileName = url.substring(from, to);

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(fileLink);

                    fileLink.click();
                })
            )
        );
    }

}

function addDays(date: Date, days: number): Date {
    const dt = new Date(date.getTime())
    dt.setDate(dt.getDate() + days);
    return dt
}

function roundDateToHour(date: Date): Date {
    const dt = new Date(date.getTime())
    dt.setHours(dt.getHours() + Math.round(dt.getMinutes() / 60));
    dt.setMinutes(0, 0, 0);
    return dt
}

export interface Export {
    index: number,
    url: string
}

class ExportListingSessionRequest {
    id: string;
    startDate: string;
    stopDate: string;

    constructor(id: string, startDate: string, stopDate: string) {
        this.id = id;
        this.startDate = startDate;
        this.stopDate = stopDate
    }
}

interface ExportListingSessionResponse {
    exports: Export[]
}

export default new ExportService();
