







































import Vue from "vue";
import Component from "vue-class-component";
import { Item } from "@/services/model/item";
import { mapGetters, mapActions } from "vuex";
import { Session } from "@/store/session.module";
import ExportService, { Export } from "@/services/export";
import ItemTable from "@/components/ItemTable.vue";

const ListingSessionProps = Vue.extend({
  props: {
    session: Session,
  },
});

@Component({
  components: {
    ItemTable,
  },
  computed: mapGetters("session", ["getActiveSessionId", "getSessionItems"]),
  methods: mapActions("session", ["populate"]),
})
export default class ListingSession extends ListingSessionProps {
  collapsed: boolean = true;
  getActiveSessionId!: string;
  sessionId = this.session.id;
  populate!: (id: string) => Promise<Object>;
  getSessionItems!: (id: string) => Array<Item>;

  loadSessionItems(sessionId: string) {
    if (this.getSessionItems(sessionId).length == 0) {
      this.populate(sessionId).then((_) => {
        this.collapsed = !this.collapsed;
      });
    } else {
        this.collapsed = !this.collapsed;
    }
  }

  exportSession() {
    if (this.sessionId) {
      ExportService.exportListing(this.sessionId).then((exports: Export[]) => {
        return ExportService.downloadExports(
          exports.map((exports) => exports.url)
        ).then((_) => {
          if (this.isActiveSession()) {
            this.$emit("active-session-export");
          }
        });
      });
    }
  }

  isActiveSession() {
    return this.getActiveSessionId == this.sessionId;
  }

  getTitle() {
    if (this.isActiveSession()) return "Active Session";
    else return "Exported Session";
  }

  formatDate(dateString: string) {
    let date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }

  getColor(sessionId: string, activeSessioId: string) {
    if (sessionId == activeSessioId) {
      return "#beface";
    } else {
      return "#dbdbdb";
    }
  }
}
